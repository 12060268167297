import React from "react";

import ProductPage from "../../components/Products/ProductPage";

import brands from "../../assets/img/products/furniture/brands.png"

import p1 from "assets/img/products/furniture/1.png";
import p2 from "assets/img/products/furniture/2.jpg";
import p3 from "assets/img/products/furniture/3.png";
import p4 from "assets/img/products/furniture/4.jpg";
import p5 from "assets/img/products/furniture/5.jpg";
import p6 from "assets/img/products/furniture/6.jpg";
import p7 from "assets/img/products/furniture/7.png";
import p8 from "assets/img/products/furniture/8.png";
import p9 from "assets/img/products/furniture/9.png";
import p10 from "assets/img/products/furniture/10.jpg";
// import p11 from "assets/img/products/furniture/11.png";

import s1 from "assets/img/products/furniture/slider/1.jpg";
import s2 from "assets/img/products/furniture/slider/2.jpg";
import s3 from "assets/img/products/furniture/slider/3.jpg";

export default () => (
  <ProductPage prod="Furniture" url="furniture" brandsImg={brands} products={products} sliders={sliders} />
);

const sliders = [
    { img: s1},
    { img: s2},
    { img: s3}
]

const products = [
    { name: 'Executive Desk',      img: p1 },
    { name: 'Seatings',          img: p2 },
    { name: 'System Desking',            img: p3 },
    { name: 'Meeting Place',           img: p4 },
    { name: 'Soft Seating',           img: p5 },
    { name: 'Coffee Tables',             img: p6 },
    { name: 'Cluster Seating',img: p7 },
    { name: 'Ocassionals',   img: p8 },
    { name: 'Floorings',           img: p9 },
    { name: 'Finishes',        img: p10 }
]